.ReclaimChartImg{
    height:100%;
    width:100%;
}
.popupImg{
    height:100%;
    width:100%;
}
.btn:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}