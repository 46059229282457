.nav-item.dropdown .dropdown-menu {
    margin-top: 0;
}

.nav-item .nav-link {
    color: #000; 
}

.nav-item .nav-link:hover {
    color: #007bff; 
}