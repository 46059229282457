.aboutLogo {
    width: 200px;
    height: 200px;
}

.custom-md-fs {
    font-size: 1.1rem;
}

@media (min-width: 768px) {
    .custom-sm-fs {
        font-size: 1.25rem;
    }
}
