.scroll-to-top {
    position: fixed;
    bottom: 150px;
    right: 15px;
    z-index: 1000;
  }
  
  .scroll-to-top .btn {
    background-color: grey; 
    color: white;
    border-radius: 50%;
    width: 60px; 
    height: 60px; 
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    text-align: center;
    transition: opacity 0.3s ease;
    --bs-btn-border-color:grey;
  }
  
  .scroll-to-top .btn:hover {
    background-color: #0056b3;
  }
  
  .scroll-to-top .btn {
    opacity: 0.7;
    transition: opacity 0.3s ease;
  }
  
  .scroll-to-top .btn:hover {
    opacity: 1;
  }
  