.carousel-container {
  position: relative;
  width: 100%;
  height: 500px;
  margin: 0 auto;
  padding: 0%;
  margin-bottom: 55px;
  overflow: hidden;
}

.timer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #ccc; /* Background for the timer bar */
}

.timer-bar {
  height: 5px;
  background-color: #3498db; /* Timer bar color */
  transition: width 1s linear; /* Smooth transition for the timer */
}

/* Additional carousel styling (existing) */
.slide {
  position: relative;
  overflow: hidden;
}

.homeTitleContainer {
  padding: 10px 20px;
  text-align: center;
  background-size: cover;
  background-image: url("https://tradeitsg.com/images/HomeImg1.jpg");
  background-position: center;
  color: white;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); 
  background-blend-mode: darken;
}
.homeTitleContainer2 {
  padding: 10px 20px;
  text-align: center;
  background-size: cover;
  background-image: url('https://tradeitsg.com/images/HomeImg2.jpg');
  background-position: center;
  color: white;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); 
  background-blend-mode: darken;
}

.homeTitlePara h1 {
  max-width: 565px;
  margin: 0 auto;
  font-family: 'Cardo';
  font-weight: 400;
  line-height: 1.2;
}

.homeTitlePara2 p {
  max-width: 565px;
  margin-top: 20px;
  font-family: 'Inter', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.6;
  color: #e0e0e0;
}

