
/* Footer.css */
.footer {
    background-color: #f8f8f8;
    color: #333;
    padding: 40px 0;
    font-family: 'Open Sans', sans-serif;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .footer-sections {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    min-width: 250px;
    margin-bottom: 20px;
    text-align: start;
  }
  
  .footer-section h4 {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .footer-section p,
  .footer-section ul {
    font-size: 14px;
    line-height: 1.6;
    color: #666;
  }
  
  .footer-section ul {
    list-style: none;
    padding: 0;
  }
  
  .footer-section ul li {
    margin-bottom: 10px;
  }
  
  .footer-section ul li a {
    color: #666;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .footer-section ul li a:hover {
    color: #000;
  }
  
  .social-icons a {
    display: inline-block;
    margin-right: 10px;
    color: #666;
    font-size: 18px;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #000;
  }
  
  .footer-bottom {
    text-align: center;
    padding-top: 20px;
    border-top: 1px solid #ddd;
    margin-top: 20px;
  }
  
  .footer-bottom p {
    font-size: 14px;
    color: #999;
    margin: 0;
  }

  .social-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .social-item a {
    text-decoration: none;
    color: #666666;
    display: flex;
    flex-direction: row;
  }
  
  .social-icon {
    margin-right: 10px;
    font-size: 1.5rem;
    margin-top: auto;
    margin-bottom: auto;
  }
