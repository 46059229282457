.chat-button {
    position: fixed;
    bottom: 80px;
    right: 15px; 
    background-color: #007bff;
    color: white;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-size: 24px;
    text-align: center;
  }
  
  .chat-button:hover {
    background-color: #0056b3;
  }
  