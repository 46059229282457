.chart-container {
    background-color: #f9f9f9;
  }
  
  .chart-item {
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    background-color: #fff;
  }
  
  .chart-item:hover {
    transform: translateY(-10px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
  }
  
  .chart-image {
    border-radius: 4px;
    max-height: 150px;
  }
  
  .chart-btn {
    background-color: #007bff;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .chart-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 767.98px) {
    .chart-item {
      margin-bottom: 20px;
    }
  }
  