.custom-list {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
  }
  .container-md {
    padding-left: 15px;
    padding-right: 15px;
  }
  .custom-card {
    border: none;
    border-radius: 12px;
    overflow: hidden;
    padding: 15px;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .custom-card + .custom-card {
    margin-top: 20px;
  }
  .card img {
    width: 100%;  
    height: 20rem; 
  }
  .custom-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .card-body {
    padding: 10px;
  }
  .card-title {
    font-weight: bold;
    color: #333;
  }
  .card-text {
    margin-bottom: 15px;
    font-size: 0.9rem;
    color: #555;
  }
  .card-body h6 {
    font-size: 1.1rem;
    margin-top: 10px;
  }
  
  ul {
    padding-left: 0;
  }
  
  ul li {
    margin-bottom: 8px;
  }
  
  ul li i {
    color: #007bff;
  }
  
  ol{
    padding-left: 0%;
  }